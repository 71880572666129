import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { HttpStatusCode } from "axios";
import { HeadersUtil } from "../../utils/headersUtil";

const initialState: any = {
  userInfo: {},
  isLogin: false,
};

export const getUserInfo = createAsyncThunk("user/getUserInfo", async () => {
  return axios.get(process.env.REACT_APP_API_AUTH_URL + "/user/get-current-user-info", {
    headers: HeadersUtil.getHeadersAuth(),
  });
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {});
    builder.addCase(getUserInfo.fulfilled, (state, action: any) => {
      if (action.payload.status === HttpStatusCode.Ok) {
        state.userInfo = action.payload.data.result;
        state.isLogin = true;
      }
    });
    builder.addCase(getUserInfo.rejected, (state, action: any) => {
      state.userInfo = {};
    });
  },
});

export default userSlice.reducer;
