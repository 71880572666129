import { Accordion, Breadcrumb, Button, Form, InputGroup, ListGroup, Stack, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Procedure() {

  const navigate = useNavigate();

  const onNavigateDetail = (event: any) => {
    navigate("/quy-trinh-dinh-gia/chi-tiet/1")
  }

  return (
    <>
      <div className="section-spacing">
        <div className="container">
          <div className="row g-4">
            <div className="col-12">
              <Breadcrumb>
                <Breadcrumb.Item href="#">Trang chủ</Breadcrumb.Item>
                <Breadcrumb.Item active>Quy trình định giá</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="col-lg-8">
              <div className="procedure-search mb-3">
                <Stack direction="horizontal" gap={3}>
                  <InputGroup>
                    <Form.Control placeholder="Tìm kiếm..." aria-label="Tìm kiếm..." aria-describedby="basic-addon2" />
                    <InputGroup.Text id="basic-addon2">Tìm kiếm nâng cao</InputGroup.Text>
                  </InputGroup>
                  <Button variant="primary button-gradient-3" style={{ whiteSpace: 'nowrap', border:"none", padding: '0.75rem 0.938rem' }}>
                    Tìm kiếm
                  </Button>
                </Stack>
              </div>
              <div className="bg-white border-radius">
                <ListGroup>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>1. Khoa học tự nhiên</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>2. Khoa học kỹ thuật và công nghệ</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>3. Khoa học y, dược</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>4. Khoa học nông nghiệp</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>5. Khoa học xã hội</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className='fs-16 fw-600'>6. Khoa học nhân văn</div>
                        <div className='mt-2'>
                          <ul className="list-inline-dot mb-0 fs-14">
                            <li>Bước 1</li>
                            <li>Bước 2</li>
                            <li>Bước 3</li>
                            <li>Bước 4</li>
                            <li>Bước 5</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <a className="link-hover-line" onClick={(event) => onNavigateDetail(event)}>
                          Tiến hành định giá
                        </a>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
            <div className="col-lg-4">
              <ListGroup>
                <ListGroup.Item className="py-3 text-white bg-gradient-3 " >
                  <div className="fs-16 fw-500 text-center">Thủ tục thường gặp.</div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className='fs-14'>
                      <div className='cursor-pointer'>Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className='fs-14'>
                      <div className='cursor-pointer'>Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className='fs-14'>
                      <div className='cursor-pointer'>Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className='fs-14'>
                      <div className='cursor-pointer'>Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className='fs-14'>
                      <div className='cursor-pointer'>Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-spacing">
        <div className="container">
          <div className="row align-items-center custom-row">
            <div className="col-12 col-lg-6 custom-col">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img className="border-radius-1" src={require('../../../assets/images/marketing-about-1.jpg')} alt="" />
                  <div className="d-flex align-items-center mt-1">
                    <p>Vì tôi sẽ đi đến chi tiết nhỏ nhất về con người chúng ta</p>
                    <div className="h2 display-3 fw-normal ps-3">
                      <span className="text-gradient-1">59</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-5">
                  <img className="border-radius-1" src={require('../../../assets/images/marketing-about-2.jpg')} alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 custom-col">
              <h1 className="display-5 fw-medium mb-3">
                Một khách hàng hài lòng là
                <span className="text-highlight-gradient-1">doanh nghiệp tốt nhất</span>
                chiến lược của tất cả
              </h1>
              <p>
                Điều quan trọng là phải tự chăm sóc nỗi đau, sau đó là sự trưởng thành của bệnh nhân, nhưng đồng thời cũng sẽ có rất nhiều công việc và nỗi đau. Đi đến chi tiết nhỏ nhất, ai là của
                chúng ta?
              </p>
              <div className="d-flex align-items-center mt-4">
                <ul className="avatar-group">
                  <li>
                    <img src={require('../../../assets/images/testimonial-4.jpg')} alt="" data-rjs="2" />
                  </li>
                  <li>
                    <img src={require('../../../assets/images/testimonial-5.jpg')} alt="" data-rjs="2" />
                  </li>
                  <li>
                    <img src={require('../../../assets/images/testimonial-7.jpg')} alt="" data-rjs="2" />
                  </li>
                </ul>
                <div className="d-inline-block ps-3">
                  <p>
                    Chúng tôi có hơn 4.000 khách hàng <br className="d-md-block"></br>trên toàn thế giới
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row g-4 mb-4">
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-router text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học tự nhiên</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-lightning-charge text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học kỹ thuật và công nghệ</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-prescription2 text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học y, dược</h5>
                  </div>
                </div>
              </Animated>
            </div>
          </div>
          <div className="row g-4 mb-4">
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-cpu text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học nông nghiệp</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-feather text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học xã hội</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated animationIn="slideInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-truck text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học nhân văn</h5>
                  </div>
                </div>
              </Animated>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing pt-0">
        <div className="container">
          <h2 className="display-5 fw-medium">
            Quy trình <span className="text-highlight-gradient-1">định giá</span>
          </h2>
          <div className="mt-5">
            <hr className="hr-diagonal" />
            <div className="py-5">
              <div className="row g-4 g-lg-5">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text mb-0">01</h2>
                </div>
                <div className="col-12 col-lg-6">
                  <h3 className="fw-medium">Kế toán và báo cáo tài chính</h3>
                  <ul className="list-inline-dot mb-3 sm-heading">
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                  </ul>
                  <p>
                    Điều quan trọng là phải tự chăm sóc nỗi đau, sau đó là sự trưởng thành của bệnh nhân, nhưng đồng thời cũng sẽ có rất nhiều công việc và nỗi đau. Để đi đến chi tiết nhỏ nhất, bài
                    tập của chúng ta là gì?
                  </p>
                </div>
                <div className="col-12 col-lg-4 col-xl-3 offset-xl-1 text-lg-end">
                  <img src="../assets/images/business-inner-services-1.jpg" alt="" />
                </div>
              </div>
            </div>

            <hr className="hr-diagonal" />
            <div className="py-5">
              <div className="row g-4 g-lg-5">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text mb-0">02</h2>
                </div>
                <div className="col-12 col-lg-6">
                  <h3 className="fw-medium">Kế toán và ghi sổ theo luật định</h3>
                  <ul className="list-inline-dot mb-3 sm-heading">
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                  </ul>
                  <p>
                    Điều quan trọng là phải tự chăm sóc nỗi đau, sau đó là sự trưởng thành của bệnh nhân, nhưng đồng thời cũng sẽ có rất nhiều công việc và nỗi đau. Để đi đến chi tiết nhỏ nhất, bài
                    tập của chúng ta là gì?
                  </p>
                </div>
                <div className="col-12 col-lg-4 col-xl-3 offset-xl-1 text-lg-end">
                  <img src="../assets/images/business-inner-services-2.jpg" alt="" />
                </div>
              </div>
            </div>

            <hr className="hr-diagonal" />
            <div className="py-5">
              <div className="row g-4 g-lg-5">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text mb-0">03</h2>
                </div>
                <div className="col-12 col-lg-6">
                  <h3 className="fw-medium">Thành lập và cơ cấu công ty</h3>
                  <ul className="list-inline-dot mb-3 sm-heading">
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                  </ul>
                  <p>
                    Điều quan trọng là phải tự chăm sóc nỗi đau, sau đó là sự trưởng thành của bệnh nhân, nhưng đồng thời cũng sẽ có rất nhiều công việc và nỗi đau. Để đi đến chi tiết nhỏ nhất, bài
                    tập của chúng ta là gì?
                  </p>
                </div>
                <div className="col-12 col-lg-4 col-xl-3 offset-xl-1 text-lg-end">
                  <img src="../assets/images/business-inner-services-3.jpg" alt="" />
                </div>
              </div>
            </div>

            <hr className="hr-diagonal" />
            <div className="py-5">
              <div className="row g-4 g-lg-5">
                <div className="col-12 col-lg-2">
                  <h2 className="display-4 font-family-poppins fw-bold stroke-text mb-0">04</h2>
                </div>
                <div className="col-12 col-lg-6">
                  <h3 className="fw-medium">Báo cáo quản lý định kỳ</h3>
                  <ul className="list-inline-dot mb-3 sm-heading">
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                    <li>Mục danh sách</li>
                  </ul>
                  <p>
                    Điều quan trọng là phải tự chăm sóc nỗi đau, sau đó là sự trưởng thành của bệnh nhân, nhưng đồng thời cũng sẽ có rất nhiều công việc và nỗi đau. Để đi đến chi tiết nhỏ nhất, bài
                    tập của chúng ta là gì?
                  </p>
                </div>
                <div className="col-12 col-lg-4 col-xl-3 offset-xl-1 text-lg-end">
                  <img src="../assets/images/business-inner-services-4.jpg" alt="" />
                </div>
              </div>
            </div>
            <hr className="hr-diagonal" />
          </div>
        </div>
      </div>

      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row g-4 g-xl-5 icon-4xl" data-cues="fadeIn" data-disabled="true">
            <div
              className="col-12 col-lg-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: 'fadeIn',
                animationDuration: '600ms',
                animationTimingFunction: 'ease',
                animationDelay: '360ms',
                animationDirection: 'normal',
                animationFillMode: 'both'
              }}>
              <div className="d-flex align-items-center">
                <div className="d-inline-block pe-4">
                  <i className="bi bi-people"></i>
                </div>
                <div className="d-inline-block">
                  <h5>Experienced Professionals</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-lg-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: 'fadeIn',
                animationDuration: '600ms',
                animationTimingFunction: 'ease',
                animationDelay: '360ms',
                animationDirection: 'normal',
                animationFillMode: 'both'
              }}>
              <div className="d-flex align-items-center">
                <div className="d-inline-block pe-4">
                  <i className="bi bi-trophy"></i>
                </div>
                <div className="d-inline-block">
                  <h5>Award Winning</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-lg-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: 'fadeIn',
                animationDuration: '600ms',
                animationTimingFunction: 'ease',
                animationDelay: '360ms',
                animationDirection: 'normal',
                animationFillMode: 'both'
              }}>
              <div className="d-flex align-items-center">
                <div className="d-inline-block pe-4">
                  <i className="bi bi-star"></i>
                </div>
                <div className="d-inline-block">
                  <h5>Impressive Results</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
