export default function Contact() {
  return (
    <>
      <div className="px-3 px-md-4 px-xxl-5">
        <div className="bg-image parallax" data-bg-src="../assets/images/startup-bg.jpg">
          <div className="section-spacing-xl bg-gradient-3 bg-opacity-60">
            <div className="container text-center">
              <div className="row g-4">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h1 className="display-3 fw-semi-bold uppercase text-center mb-0">Liên hệ</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-5">
            <div className="col-12 col-lg-4">
              <h5>Phone</h5>
              <p>+123 456 7890</p>
              <p>+123 456 7891</p>
              <div className="mt-3 mt-lg-4">
                <h5>Email</h5>
                <span>contact@flatheme.net</span>
              </div>
              <div className="mt-3 mt-lg-4">
                <h5>Address</h5>
                <span>551 Swanston Street, Melbourne Victoria 3053 Australia</span>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="contact-form">
                <form className="form-border-radius" method="post" id="contactform">
                  <div className="row gx-3 gy-3">
                    <div className="col-12 col-md-6">
                      <input type="text" id="name" name="name" placeholder="Name" required />
                    </div>
                    <div className="col-12 col-md-6">
                      <input type="email" id="email" name="email" placeholder="E-Mail" required />
                    </div>
                    <div className="col-12">
                      <input type="text" id="subject" name="subject" placeholder="Subject" required />
                    </div>
                    <div className="col-12">
                      <textarea name="message" id="message" placeholder="Message"></textarea>
                    </div>
                  </div>
                  <button className="button button-lg button-rounded button-gradient-5 button-hover-slide mt-3" type="submit">
                    <span data-text="Send Message">Send Message</span>
                  </button>
                </form>
                <div className="submit-result">
                  <span id="success">Thank you! Your Message has been sent.</span>
                  <span id="error">Something went wrong, Please try again!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
