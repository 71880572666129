import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/svg/logo2.svg';
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from '../libs/hooks';
import { AuthConstant } from '../constants/authConstant';
import { useEffect } from 'react';
import { getUserInfo } from '../libs/reducers/userSlice';

export default function Header() {
  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _token = params.get('access_token');
  const cookie = new Cookies();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const isLogin = useAppSelector((state) => state.userInfo.isLogin);

  if (_token) {
    const expires = new Date();
    expires.setDate(expires.getDate() + AuthConstant.EXPIRES_TOKEN);
    cookie.remove(AuthConstant.ACCESS_TOKEN);
    cookie.remove(AuthConstant.PUBLIC_KEY);
    cookie.set(AuthConstant.ACCESS_TOKEN, _token, {
      path: '/',
      expires: expires
    });
    window.location.href = '/';
  }

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const login = () => {
    let domain = window.location.origin;
    var url = process.env.REACT_APP_AUTH_URL + '/login?redirect_uri=' + domain || '';
    window.location.href = url;
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    // navigate("/");
    window.location.reload();
  };

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-logo">
            <a href="/">
              <img src={logo} style={{ height: '50px' }} alt="" />
            </a>
          </div>
          <div className="header-menu nav-link-hover-line ml-0">
            <ul className="nav">
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/trang-chu' ? ' active' : '')} to="/trang-chu">
                  Trang chủ
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/quy-trinh-dinh-gia' ? ' active' : '')} to="/quy-trinh-dinh-gia">
                  Quy trình định giá
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/ve-chung-toi' ? ' active' : '')} to="/ve-chung-toi">
                  Về NSTD
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/dich-vu' ? ' active' : '')} to="/#">
                  Dịch vụ
                </Link>
              </li>

              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/lien-he' ? ' active' : '')} to="/lien-he">
                  Liên hệ
                </Link>
              </li>
            </ul>
          </div>
          <div className="header-extra">
            {!isLogin ? (
              <div className="button button-sm button-gradient-3 button-rounded button-hover-slide" onClick={login}>
                <span data-text="Đăng nhập">Đăng nhập</span>
              </div>
            ) : (
              <>
                <div className="header-menu nav-link-hover-line">
                  <ul className="nav">
                    <li className="nav-item">
                      <i className="bi bi-person-circle fs-4 me-2"></i>
                      <a className="nav-link" href="javascript:void(0)">
                        {userInfo.email}
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown-item">
                          <Link className="nav-dropdown-link" to="">
                            Thông tin cá nhân
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <Link className="nav-dropdown-link" to="/sang-che-cua-toi">
                            Sáng chế của tôi
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <a className="nav-dropdown-link" onClick={logout}>
                            Đăng xuất
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <button className="mobile-menu-toggle">
            <span></span>
          </button>
        </div>
      </div>
    </>
  );
}
