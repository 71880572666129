import Layout from "../layouts/Layout";
import About from "../pages/about/About";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Contact from "../pages/contact/Contact";
import Dashboard from "../pages/dashboard/Dashboard";
import HomePage from "../pages/homepage/HomePage";
import Procedure from "../pages/procedure/Procedure";
import ProcedureDetail from "../pages/procedure/ProcedureDetail";
import Service from "../pages/service/Service";

export const indexRouter: any = {
  path: "/",
  element: <Layout />,
  children: [
    { path: "trang-chu", element: <HomePage /> },
    { path: "thong-ke", element: <Dashboard /> },
    { path: "dich-vu", element: <Service /> },
    { path: "quy-trinh-dinh-gia", element: <Procedure /> },
    { path: "ve-chung-toi", element: <About /> },
    { path: "lien-he", element: <Contact /> },
    { path: "dang-nhap", element: <Login /> },
    { path: "dang-ky", element: <Register /> },
    { path: "quy-trinh-dinh-gia/chi-tiet/:id", element: <ProcedureDetail /> },
  ],
};
