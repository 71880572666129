import { configureStore } from '@reduxjs/toolkit';
import userInfoReducer from './reducers/userSlice';
const store = configureStore({
  reducer: {
    userInfo: userInfoReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
    // .concat(createLogger())
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
