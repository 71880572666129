import { Accordion, Breadcrumb, Button, Form, InputGroup, ListGroup, Stack, Table } from 'react-bootstrap';

export default function ProcedureDetail() {
  return (
    <>
      <div className="section-spacing">
        <div className="container">
          <div className="row g-4">
            <div className="col-12">
              <Breadcrumb>
                <Breadcrumb.Item href="#">Trang chủ</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Quy trình định giá</Breadcrumb.Item>
                <Breadcrumb.Item active>Chi tiết</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="col-lg-8">
              <div className='d-flex justify-content-between'>
              <div className="fs-24 fw-500 mb-4">Khoa học tự nhiên</div>
              <div>
                      <Button variant='primary' className='app-btn me-2'>Nộp hồ sơ trực tuyến</Button>
                      <Button variant="outline-primary" className='me-2 app-btn-outline'><span><i className="fa-solid fa-print"></i> In</span></Button>
                      <Button variant="outline-primary" className='app-btn-outline'><span><i className="fa-solid fa-file-pdf"></i> Pdf</span></Button>
                      </div>
              </div>
              <div className="bg-white border-radius">
                <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="fw-500">Trình tự thực hiện</div>
                      
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="fs-14">
                        1. Ủy ban nhân dân cấp xã (nếu sáp nhập, chia, tách trường mẫu giáo, trường mầm non, nhà trẻ công lập); tổ chức, cá nhân (nếu sáp nhập, chia, tách trường mẫu giáo, trường mầm
                        non, nhà trẻ dân lập, tư thục) gửi trực tiếp hoặc qua bưu điện 01 bộ hồ sơ đến Ủy ban nhân dân cấp huyện;{' '}
                      </div>
                      <div className="fs-14 mt-3">
                        2. Trong thời hạn 05 ngày làm việc, kể từ ngày nhận đủ hồ sơ hợp lệ, Ủy ban nhân dân cấp huyện có trách nhiệm chỉ đạo Phòng Giáo dục và Đào tạo tổ chức thẩm định hồ sơ và thẩm
                        định thực tế các điều kiện sáp nhập, chia, tách trường mẫu giáo, trường mầm non, nhà trẻ; trong thời hạn 10 ngày làm việc, Phòng Giáo dục và Đào tạo chủ trì, phối hợp với các
                        phòng chuyên môn có liên quan thẩm định, trình Chủ tịch Ủy ban nhân dân cấp huyện;
                      </div>
                      <div className="fs-14 mt-3">
                        3. Trong thời hạn 05 ngày làm việc, kể từ ngày nhận được ý kiến thẩm định bằng văn bản của Phòng Giáo dục và Đào tạo và các phòng chuyên môn có liên quan, nếu đáp ứng các điều
                        kiện theo quy định thì Chủ tịch Ủy ban nhân dân cấp huyện ra quyết định sáp nhập, chia, tách; nếu không đáp ứng các điều kiện theo quy định thì có văn bản trả lời nêu rõ lý do.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="fw-500">Lĩnh vực</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="fs-14">Khoa học tự nhiên</div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="fw-500">Đối tượng thực hiện</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="fs-14">Công dân, Doanh nghiệp, Tổ chức</div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="fw-500">Cách thức thực hiện</div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <div>
                        <Table bordered hover>
                          <thead>
                            <tr>
                              <th className="fs-14 fw-500">Hình thức nộp</th>
                              <th className="fs-14 fw-500">Thời gian giải quyết</th>
                              <th className="fs-14 fw-500">Phí, lệ phí</th>
                              <th className="fs-14 fw-500">Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="fs-14">Trực tiếp</td>
                              <td className="fs-14">20 Ngày làm việc</td>
                              <td className="fs-14"></td>
                              <td className="fs-14">20 ngày làm việc kể từ ngày nhận đủ hồ sơ hợp lệ</td>
                            </tr>
                            <tr>
                              <td className="fs-14">Dịch vụ bưu chính</td>
                              <td className="fs-14">20 Ngày làm việc </td>
                              <td className="fs-14"></td>
                              <td className="fs-14">20 ngày làm việc kể từ ngày nhận đủ hồ sơ hợp lệ</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="fw-500">Thành phần hồ sơ</div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <div className="mb-3">Giấy tờ phải xuất trình:</div>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="fs-14 fw-500">STT</th>
                            <th className="fs-14 fw-500">Tên giấy tờ</th>
                            <th className="fs-14 fw-500">Số lượng bản</th>
                            <th className="fs-14 fw-500">Mẫu giấy tờ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="fs-14">1</td>
                            <td className="fs-14">
                              Đề án sáp nhập, chia, tách trường mẫu giáo, trường mầm non, nhà trẻ, trong đó có phương án để bảo đảm quyền, lợi ích hợp pháp của trẻ em, giáo viên, cán bộ quản lý và
                              nhân viên
                            </td>
                            <td className="fs-14">1 Bản chính (Tải mẫu kết xuất, bổ sung thông tin, ký tên đóng dấu và Scan/Chụp ảnh đính kèm)</td>
                            <td className="fs-14"></td>
                          </tr>
                          <tr>
                            <td className="fs-14">2</td>
                            <td className="fs-14">20 ngày làm việc kể từ ngày nhận đủ hồ sơ hợp lệ</td>
                            <td className="fs-14">20 ngày làm việc kể từ ngày nhận đủ hồ sơ hợp lệ</td>
                            <td className="fs-14"></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="fw-500">Căn cứ pháp lý</div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="fs-14 fw-500">Số kí hiệu</th>
                            <th className="fs-14 fw-500">Trích yếu</th>
                            <th className="fs-14 fw-500">Ngày ban hành</th>
                            <th className="fs-14 fw-500">Cơ quản ban hành</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="fs-14">46/2017/NĐ-CP </td>
                            <td className="fs-14">Quy định về điều kiện đầu tư và hoạt động trong lĩnh vực giáo dục</td>
                            <td className="fs-14">21-04-2017</td>
                            <td className="fs-14">Chính phủ</td>
                          </tr>
                          <tr>
                            <td className="fs-14">135/2018/NĐ-CP</td>
                            <td className="fs-14">
                              sửa đổi, bổ sung một số điều của Nghị định số 46/2017/NĐ-CP ngày 21/4/2017 của Chính phủ quy định về điều kiện đầu tư và hoạt động trong lĩnh vực giáo dục
                            </td>
                            <td className="fs-14">04-10-2018</td>
                            <td className="fs-14">Chính phủ</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <div className="fw-500">Phí</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Không.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <div className="fw-500">Lệ phí</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Không
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <div className="fw-500">Yêu cầu điều kiện</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Không có thông tin.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <div className="fw-500">Số bộ hồ sơ</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      1
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <div className="fw-500">Kết quả thực hiện</div>
                    </Accordion.Header>
                    <Accordion.Body>
                    Quyết định sáp nhập, chia, tách trường mẫu giáo, trường mầm non, nhà trẻ của Chủ tịch UBND cấp huyện
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <div className="fw-500">Thời hạn giải quyết</div>
                    </Accordion.Header>
                    <Accordion.Body>
                    20 ngày làm việc kể từ ngày nhận đủ hồ sơ hợp lệ
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      <div className="fw-500">Cơ quan thực hiện</div>
                    </Accordion.Header>
                    <Accordion.Body>
                    Phòng Giáo dục và Đào tạo
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <div className="fw-500">Cơ quan ban hành</div>
                    </Accordion.Header>
                    <Accordion.Body>
                    UBND TP.HCM
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <div className="fw-500">Cơ quan phối hợp</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-lg-4">
              <ListGroup>
                <ListGroup.Item className="py-3 text-dark" style={{ backgroundColor: '#f0f2f3', border: '1px solid #f0f2f3' }}>
                  <div className="fs-16 fw-500 text-center">Thủ tục thường gặp.</div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="fs-14">
                      <div className="cursor-pointer">Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="fs-14">
                      <div className="cursor-pointer">Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="fs-14">
                      <div className="cursor-pointer">Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="fs-14">
                      <div className="cursor-pointer">Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start">
                    <div className="me-4">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="fs-14">
                      <div className="cursor-pointer">Chứng thực bản sao từ bản chính giấy tờ, văn bản do cơ quan tổ chức có thẩm quyền của Việt Nam cấp hoặc chứng nhận.</div>
                      <div>UBND Thành phố Hồ Chí Minh</div>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className='fs-20 fw-500'>Các thủ tục hành chính liên quan</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
