import MetisMenu from '@metismenu/react';
import { Link } from 'react-router-dom';

export default function Dashboard() {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-3 p-0">
            <div className="navbar-menu navigation-floating">
              <a className="button button-dark text-white cursor-pointer" style={{ width: '100%' }}>
                <span>
                  <i className="fa-solid fa-plus"></i>
                </span>{' '}
                Thêm mới hồ sơ
              </a>
              <div className="">
                <MetisMenu>
                  <li>
                    <Link to="#" className="has-arrow">
                      HỒ SƠ MỚI
                    </Link>
                    <ul>
                      <li>
                        <Link to="about">Hồ sơ mới tạo</Link>
                      </li>
                      <li>
                        <Link to="contact">Hồ sơ chờ tiếp nhận</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#" className="has-arrow">
                      BỔ SUNG HỒ SƠ
                    </Link>
                    <ul>
                      <li>
                        <Link to="#">Hồ sơ đã gửi bổ sung</Link>
                      </li>
                      <li>
                        <Link to="contact">Hồ sơ chờ tiếp nhận</Link>
                      </li>
                      <li>
                        <Link to="contact">Hồ sơ chờ bổ sung</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#" className="has-arrow">
                      THANH TOÁN PHÍ
                    </Link>
                    <ul>
                      <li>
                        <Link to="contact">YÊU CẦU THANH TOÁN</Link>
                      </li>
                      <li>
                        <Link to="contact">CHỜ XÁC NHẬN THANH TOÁN</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">HỒ SƠ ĐANG XỬ LÝ</Link>
                  </li>
                  <li>
                    <Link to="#">HỒ SƠ HOÀN THÀNH</Link>
                  </li>
                  <li>
                    <Link to="#">HỒ SƠ CỦA TÔI</Link>
                  </li>
                </MetisMenu>
              </div>
            </div>
          </div>
          <div className="col-9 p-0 ">
            <div className="v-content">
              <div className="v-content-wrap">
                <div className="row-header no__hidden_class">
                  <div className="background-triangle-big">
                    <span>HỒ SƠ ĐANG XỬ LÝ</span>
                  </div>
                  <div className="layout row wrap header_tools row-blue">
                    <div className="flex pl-3 text-ellipsis text-bold" style={{ position: 'relative' }}>
                      <div className="v-input v-text-field v-text-field--single-line v-text-field--solo v-text-field--enclosed v-text-field--placeholder theme--light">
                        <div className="v-input__control">
                          <div className="v-input__slot">
                            <div className="v-text-field__slot">
                              <input type="text" placeholder="Tìm kiếm theo tên hồ sơ, mã hồ sơ, tên thủ tục, chủ hồ sơ ..." />
                            </div>
                          </div>
                          <div className="v-text-field__details">
                            <div className="v-messages theme--light">
                              <div className="v-messages__wrapper"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex text-right" style={{ marginLeft: 'auto', maxWidth: '50px' }}>
                      <span className="v-tooltip v-tooltip--top">
                        <span>
                          <button type="button" className="my-0 mx-0 mr-2 px-0 v-btn v-btn--icon theme--light">
                            <div className="v-btn__content">
                              <i aria-hidden="true" className="v-icon fas fa fa-filter theme--light" style={{ fontSize: '20px' }}></i>
                            </div>
                          </button>
                        </span>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
