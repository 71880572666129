import { Animated } from "react-animated-css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

export default function HomePage() {
  return (
    <div className="container-full-bg">
      <div className="section-spacing-sm pb-0">
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-xl-9">
              <h1 className="display-6 fw-semi-bold uppercase">
                <span className="text-gradient-3 fw-bold display-3" style={{lineHeight: "1.8"}}>Hệ thống định giá</span>
                <span className="d-block fw-bold text-start">Dữ Liệu Thị Trường <br/> Khoa Học - Công Nghệ Quốc Gia</span>
              </h1>
            </div>
            <div className="col-12 col-xl-3 text-xl-end">
              <div className="row g-4">
                <div className="col-12 col-sm-6 col-xl-12">
                  <h2 className="display-5 font-family-tertiary mb-0">11,000</h2>
                  <p>Sáng chế đã được định giá</p>
                </div>
                <div className="col-12 col-sm-6 col-xl-12">
                  <h2 className="display-5 font-family-tertiary mb-0">
                    <span className="counter">200+</span>
                  </h2>
                  <p>Quy trình được số hóa<br/> và cập nhật</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing-sm pb-0">
        <div className="container">
          <div className="row g-4 g-lg-5 align-items-center">
            <div className="col-12 col-lg-5 col-xl-4">
              <h2 className="font-family-tertiary">Hơn 4,000 cá nhân, tổ chức, doanh nghiệp đã sự dụng và hài lòng.</h2>
              {/* <ul className="list-inline mt-3">
                <li>
                  <a className="button-circle button-circle-dark button-circle-hover-slide testimonial-slider-2-prev" href="#">
                    <i className="bi bi-arrow-left"></i>
                    <i className="bi bi-arrow-left"></i>
                  </a>
                </li>
                <li>
                  <a className="button-circle button-circle-dark button-circle-hover-slide testimonial-slider-2-next" href="#">
                    <i className="bi bi-arrow-right"></i>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </li>
              </ul> */}
              <a className="button button-sm button-gradient-3 button-rounded button-hover-slide mt-3" href="/dang-nhap"><span data-text="Tiến hành định giá">Tiến hành định giá</span></a>
            </div>
            <div className="col-12 col-lg-7 col-xl-8">
              <Swiper
                className="d-flex"
                modules={[Pagination]}
                spaceBetween={30} // Khoảng cách giữa các slide
                slidesPerView={2} // Số slide hiển thị cùng lúc
              >
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                    </div>
                    <div className="quote-author">
                      <img src={require("../../../assets/images/testimonial-1.jpg")} alt="" data-rjs="2" />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Christina Gray</h6>
                        <span>CMO @Company</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                    </div>
                    <div className="quote-author">
                      <img src={require("../../../assets/images/testimonial-2.jpg")} alt="" data-rjs="2" />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Alexander Warren</h6>
                        <span>CEO @Company</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                    </div>
                    <div className="quote-author">
                      <img src={require("../../../assets/images/testimonial-3.jpg")} alt="" data-rjs="2" />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Barbara Myers</h6>
                        <span>Creative Director</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>


      <div className="section-spacing-sm">
        <div className="container">
          <Swiper
            modules={[Autoplay]}
            className={"brands"}
            spaceBetween={50} // Khoảng cách giữa các slide
            // slidesPerView={6} // Số slide hiển thị cùng lúc
            breakpoints={{
              0: {
                // width: 576,
                slidesPerView: 2,
              },
              576: {
                // width: 576,
                slidesPerView: 4,
              },
              768: {
                // width: 768,
                slidesPerView: 6,
              },
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-1-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-2-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-3-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-4-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-5-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-6-dark.png")} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require("../../../assets/images/client-1-dark.png")} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="section-spacing pt-0">
        <div className="container">
          <div className="row g-4 mb-4">
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-lightning text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Lĩnh vực Khoa Học Tự Nhiên</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-cpu text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">
                      Lĩnh vực Khoa Học Kỹ Thuật - Công Nghệ
                    </h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-prescription2 text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Lĩnh vực Khoa Học Y Dược</h5>
                  </div>
                </div>
              </Animated>
            </div>
          </div>
          <div className="row g-4 mb-4">
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-5 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-feather2 text-gradient-5"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Lĩnh vực Khoa Học Nông Nghiệp</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-1 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-feather text-gradient-1"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Lĩnh vực Khoa Học Xã Hội</h5>
                  </div>
                </div>
              </Animated>
            </div>
            <div className="col-12 col-lg-4">
              <Animated
                animationIn="slideInUp"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className="bg-gradient-2 bg-opacity-10 p-4 p-md-5 border-radius-1 backdrop-filter-blur d-md-flex align-items-center">
                  <div className="mb-2 mb-md-0 me-md-4 me-lg-5 icon-4xl">
                    <i className="bi bi-truck text-gradient-2"></i>
                  </div>
                  <div>
                    <h5 className="text-dark">Khoa học nhân văn</h5>
                  </div>
                </div>
              </Animated>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing pt-0">
        <div className="container">
          <hr className="hr-diagonal" />
          <div className="py-4 py-lg-5 px-lg-4 px-xl-5 border-dark" data-hover-img="assets/images/media-agency-services-1.jpg">
            <div className="d-flex align-items-center">
              <div className="pe-4 pe-xl-5 icon-3xl">
                <i className="ti-crown"></i>
              </div>
              <div>
                <div className="h1 display-2 font-family-tertiary">Số hóa quy trình</div>
                <ul className="list-inline-dot">
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="hr-diagonal" />
          <div className="py-4 py-lg-5 px-lg-4 px-xl-5 border-dark" data-hover-img="assets/images/media-agency-services-2.jpg">
            <div className="d-flex align-items-center">
              <div className="pe-4 pe-xl-5 icon-3xl">
                <i className="ti-star"></i>
              </div>
              <div>
                <div className="h1 display-2 font-family-tertiary">Kiểm định chất lượng</div>
                <ul className="list-inline-dot">
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="hr-diagonal" />
          <div className="py-4 py-lg-5 px-lg-4 px-xl-5 border-dark" data-hover-img="assets/images/media-agency-services-3.jpg">
            <div className="d-flex align-items-center">
              <div className="pe-4 pe-xl-5 icon-3xl">
                <i className="ti-palette"></i>
              </div>
              <div>
                <div className="h1 display-2 font-family-tertiary">Định giá</div>
                <ul className="list-inline-dot">
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                  <li>List item</li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="hr-diagonal" />
        </div>
      </div>

      <div className="section-spacing pt-0" style={{paddingBottom: '400px'}}>
        <div className="container">
          <div className="row g-4 g-xl-5 align-items-center ">
            <div className="col-12 col-lg-6">
              <h2 className="display-5 fw-normal">Vinh danh</h2>
              {/*<ul className="list-inline mt-3">*/}
              {/*<li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-prev"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-next"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            {/*<div className="col-12 col-lg-6">*/}
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore*/}
            {/*    et dolore magna aliqua</p>*/}
            {/*  <div className="mt-3">*/}
            {/*    <a className="button-fancy-gradient-1" href="#">*/}
            {/*      View More Posts*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="mt-4 mt-lg-5">
            <Swiper
              modules={[Pagination]}
              spaceBetween={40} // Khoảng cách giữa các slide
              // slidesPerView={3} // Số slide hiển thị cùng lúc
              breakpoints={{
                0: {
                  // width: 576,
                  slidesPerView: 1,
                },
                576: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-1.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Work</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Best work environment
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-2.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Keeping things simple
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-3.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Tips</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Tips for a healthy lifestyle
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img src={require("../../../assets/images/blog-slide-4.jpg")} alt="" />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a className="link-hover-line" href="blog/blog-single.html">
                        Work-Life Balance
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
